import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PurchasePlusBaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const CreatePricedCataloguedProductDocument = gql`
  mutation CreatePricedCataloguedProduct($input: CreatePricedCataloguedProductInput!) {
    createPricedCataloguedProduct(input: $input) {
      pricedCataloguedProduct {
        id
        sellUnitPrice
        sellUnitTaxPercentage
        catalogue {
          id
          supplier {
            id
            name
          }
        }
        product {
          id
        }
      }
      errors {
        ...PurchasePlusBaseError
      }
    }
  }
  ${PurchasePlusBaseErrorFragmentDoc}
` as unknown as DocumentNode<
  Types.CreatePricedCataloguedProductMutation,
  Types.CreatePricedCataloguedProductMutationVariables
>
