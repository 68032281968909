import { Dispatch, SetStateAction, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { Checkbox } from '@/modules/shared/components/checkbox/Checkbox'
import { CreateProductFormInputs } from '@/modules/shared/components/create-product-modal/CreateProductFormSchema'
import NumberInput from '@/modules/shared/components/number-input/NumberInput'
import PackNameDropdown from '@/modules/shared/components/pack-name-dropdown/PackNameDropdown'
import { HELP_LINK_PRODUCT_CREATION } from '@/modules/shared/help'

interface CreateProductSellPackProps {
  defaultSellPack: boolean
  setDefaultSellPack: Dispatch<SetStateAction<boolean>>
  stockItem?: boolean
  defaultItemSellPackName?: string | null
  defaultItemSellQuantity?: number | null
  disabled?: boolean
}

export default function CreateProductSellPack({
  defaultSellPack,
  setDefaultSellPack,
  stockItem,
  defaultItemSellPackName,
  defaultItemSellQuantity,
  disabled,
}: CreateProductSellPackProps) {
  const { t } = useTranslation()
  const formMethods = useFormContext<CreateProductFormInputs>()
  const [remount, setRemount] = useState(0)
  const [resetToDefault, setResetToDefault] = useState(false)

  const onUseDefaultSellPack = () => {
    if (!defaultSellPack) {
      setResetToDefault(true)
      setRemount(remount + 1)
      formMethods.setValue('itemSellPackName', 'each')
      formMethods.setValue('itemSellQuantity', 1)
    }
    setDefaultSellPack(!defaultSellPack)
  }

  return (
    <div>
      <p className="font-bold">{t('createdProduct.sellPack.title', 'Sell Pack and Sell Quantity')}</p>
      <div className="mb-4 text-gray-500">
        <Trans t={t} i18nKey="createProduct.sellPack.description">
          The sell pack and sell pack quantity describes what will be received when an order for 1 of these items is
          placed. For example "Carton of 24", for a Carton of 24 x 375ml Cans. If the product is sold as a single unit
          only, you can leave this section unchanged. Please read our detailed{' '}
          <a
            data-testid="view-support-link"
            href={HELP_LINK_PRODUCT_CREATION}
            target="_blank"
            rel="noreferrer"
            className="text-primary"
          >
            product creation guide
          </a>{' '}
          to see examples of common sell pack variants
        </Trans>
      </div>
      {stockItem && (
        <div className="mb-4 rounded-md bg-gray-200 p-4">
          {t(
            'createProduct.isStockItem',
            'This product is held as a Stock Item and the Sell Pack and Sell Quantity cannot be changed.'
          )}
        </div>
      )}
      <Checkbox
        onChange={onUseDefaultSellPack}
        isSelected={defaultSellPack}
        dataTestId="sell-pack-checkbox"
        disabled={disabled || stockItem}
      >
        <p>{t('createProduct.sellPackCheckbox', 'Item is sold as a single unit only')}</p>
      </Checkbox>
      <div className="mt-4 flex flex-col gap-3 sm:flex-row" key={remount}>
        <div className="flex w-56 flex-col gap-y-1">
          <p className="font-bold">{t('general.sellPackName', 'Sell Pack Name')}</p>
          <PackNameDropdown
            disabled={defaultSellPack || disabled || stockItem}
            sellPackName
            defaultValue={defaultItemSellPackName}
            resetToDefault={resetToDefault}
          />
        </div>
        <p className="mt-0 sm:mt-9">{t('general.of', 'of')}</p>
        <div className="flex w-56 flex-col gap-y-1">
          <p className="font-bold">{t('general.sellPackQuantity', 'Sell Pack Quantity')}</p>
          <Controller
            control={formMethods.control}
            name="itemSellQuantity"
            render={({ field }) => (
              <NumberInput
                className="h-[2.875rem] w-full rounded-md text-sm"
                defaultValue={(!resetToDefault && defaultItemSellQuantity) || 1}
                isDisabled={defaultSellPack || disabled || stockItem}
                onChange={(e) => field.onChange(e)}
                aria-label="item-sell-quantity"
                data-testid="sell-pack-quantity-input"
              />
            )}
          />
        </div>
      </div>
    </div>
  )
}
