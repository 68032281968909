import { matchRoutes, useLocation } from 'react-router-dom'

import {
  buyListRoutes,
  catalogRoutes,
  inventoryRoutes,
  invoiceRoutes,
  myProductsRoutes,
  pointOfSaleRoutes,
  purchaseOrderRoutes,
  purchaserRoutes,
  recipeRoutes,
  reportsRoutes,
  requisitionRoutes,
  supplierRoutes,
  systemSettingRoutes,
  usersRoutes,
} from '@/modules/shared/constants'

const useFindMatchRoutes = () => {
  const location = useLocation()

  const allRoutes = {
    requisitionRoutes,
    purchaseOrderRoutes,
    invoiceRoutes,
    buyListRoutes,
    catalogRoutes,
    myProductsRoutes,
    recipeRoutes,
    inventoryRoutes,
    supplierRoutes,
    reportsRoutes,
    usersRoutes,
    systemSettingRoutes,
    purchaserRoutes,
    pointOfSaleRoutes,
    // ... Add more as needed
  }

  const findSideBarIndexKey = (allRouteKey: string) => {
    return Object.keys(allRoutes).findIndex((routeKey) => routeKey === allRouteKey)
  }

  const matchedRouteIndex = Object.values(allRoutes).findIndex((route) => matchRoutes(route, location))

  const routeMatches = Object.values(allRoutes).map((_, index) => index === matchedRouteIndex)

  const sideBarIndexKeys: Record<string, number> = {
    requisitionSideBarKey: findSideBarIndexKey('requisitionRoutes'),
    purchaseOrdersSideBarKey: findSideBarIndexKey('purchaseOrderRoutes'),
    invoiceSideBarKey: findSideBarIndexKey('invoiceRoutes'),
    buyListSideBarKey: findSideBarIndexKey('buyListRoutes'),
    catalogSideBarKey: findSideBarIndexKey('catalogRoutes'),
    myProductSideBarKey: findSideBarIndexKey('myProductsRoutes'),
    recipeSideBarKey: findSideBarIndexKey('recipeRoutes'),
    inventorySideBarKey: findSideBarIndexKey('inventoryRoutes'),
    supplierSideBarKey: findSideBarIndexKey('supplierRoutes'),
    reportsSideBarKey: findSideBarIndexKey('reportsRoutes'),
    usersSideBarKey: findSideBarIndexKey('usersRoutes'),
    systemSettingSideBarKey: findSideBarIndexKey('systemSettingRoutes'),
    purchaserSidebarKey: findSideBarIndexKey('purchaserRoutes'),
    pointOfSaleSideBarKey: findSideBarIndexKey('pointOfSaleRoutes'),
    // ... Add more as needed
  }

  return {
    requisitionRoutesMatch: routeMatches[sideBarIndexKeys.requisitionSideBarKey],
    purchaseOrdersRoutesMatch: routeMatches[sideBarIndexKeys.purchaseOrdersSideBarKey],
    invoiceRoutesMatch: routeMatches[sideBarIndexKeys.invoiceSideBarKey],
    buyListRoutesMatch: routeMatches[sideBarIndexKeys.buyListSideBarKey],
    catalogRoutesMatch: routeMatches[sideBarIndexKeys.catalogSideBarKey],
    myProductRoutesMatch: routeMatches[sideBarIndexKeys.myProductSideBarKey],
    recipeRoutesMatch: routeMatches[sideBarIndexKeys.recipeSideBarKey],
    inventoryRoutesMatch: routeMatches[sideBarIndexKeys.inventorySideBarKey],
    supplierRoutesMatch: routeMatches[sideBarIndexKeys.supplierSideBarKey],
    reportsRoutesMatch: routeMatches[sideBarIndexKeys.reportsSideBarKey],
    usersRoutesMatch: routeMatches[sideBarIndexKeys.usersSideBarKey],
    systemSettingRoutesMatch: routeMatches[sideBarIndexKeys.systemSettingSideBarKey],
    purchaserRoutesMatch: routeMatches[sideBarIndexKeys.purchaserSidebarKey],
    pointOfSaleRoutesMatch: routeMatches[sideBarIndexKeys.pointOfSaleSideBarKey],
    sideBarIndexKeys,
    accordionDefaultKey: matchedRouteIndex !== -1 ? matchedRouteIndex : null,
  }
}

export default useFindMatchRoutes
