import { useMutation } from '@apollo/client'
import { produce } from 'immer'

import { CreatePricedCataloguedProductDocument } from '@/graphql/purchase-plus/generated/createPricedCataloguedProduct.generated'
import {
  GetCurrentSupplierProductsQuery,
  ProductEdge,
  Supplier,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { GET_CURRENT_SUPPLIER_PRODUCTS } from '@/graphql/purchasing/operations/queries/getCurrentSupplierProducts'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'
import { ProductType } from '@/modules/shared/types/ProductType'

interface UseCreatePricedCataloguedProductProps {
  catalogId?: number
  line?: ProductType
}

export default function useCreatePricedCatalogedProduct({ catalogId, line }: UseCreatePricedCataloguedProductProps) {
  const mutation = useMutation(CreatePricedCataloguedProductDocument, {
    context: {
      uri: PURCHASE_PLUS_GRAPHQL_API,
    },
    update: (cache, data) => {
      if (!!line && !data.data?.createPricedCataloguedProduct.errors?.length) {
        const queryToCacheSupplierProducts = {
          query: GET_CURRENT_SUPPLIER_PRODUCTS,
          variables: {
            catalogueId: catalogId,
            sort: {
              property: 'created_at',
              direction: 'DESC',
            },
          },
        }

        const availableSupplierProductsCache = cache.readQuery(queryToCacheSupplierProducts)

        const newAvailableProductsCache = produce(
          availableSupplierProductsCache,
          (draft: GetCurrentSupplierProductsQuery) => {
            const { edges } = (draft.currentLegalEntity as Supplier)?.availableProducts || {}
            if (draft.currentLegalEntity && (draft.currentLegalEntity as Supplier).availableProducts) {
              ;(draft.currentLegalEntity as Supplier).availableProducts!.edges = edges?.map((e) => {
                if (e?.node?.id === line?.product?.id) {
                  if (e && e.node) {
                    e.node.catalogued = true
                  }
                }
                return e
              }) as ProductEdge[]
            }
          }
        )
        cache.writeQuery({ ...queryToCacheSupplierProducts, data: newAvailableProductsCache })
      }
    },
  })

  return mutation
}
